'use strict';

var normalize = require('./normalize');
var DefinedInfo = require('./lib/util/defined-info');
var Info = require('./lib/util/info');
var data = 'data';
module.exports = find;
var valid = /^data[-\w.:]+$/i;
var dash = /-[a-z]/g;
var cap = /[A-Z]/g;
function find(schema, value) {
  var normal = normalize(value);
  var prop = value;
  var Type = Info;
  if (normal in schema.normal) {
    return schema.property[schema.normal[normal]];
  }
  if (normal.length > 4 && normal.slice(0, 4) === data && valid.test(value)) {
    // Attribute or property.
    if (value.charAt(4) === '-') {
      prop = datasetToProperty(value);
    } else {
      value = datasetToAttribute(value);
    }
    Type = DefinedInfo;
  }
  return new Type(prop, value);
}
function datasetToProperty(attribute) {
  var value = attribute.slice(5).replace(dash, camelcase);
  return data + value.charAt(0).toUpperCase() + value.slice(1);
}
function datasetToAttribute(property) {
  var value = property.slice(4);
  if (dash.test(value)) {
    return property;
  }
  value = value.replace(cap, kebab);
  if (value.charAt(0) !== '-') {
    value = '-' + value;
  }
  return data + value;
}
function kebab($0) {
  return '-' + $0.toLowerCase();
}
function camelcase($0) {
  return $0.charAt(1).toUpperCase();
}