'use strict';

var create = require('./util/create');
var caseInsensitiveTransform = require('./util/case-insensitive-transform');
module.exports = create({
  space: 'xmlns',
  attributes: {
    xmlnsxlink: 'xmlns:xlink'
  },
  transform: caseInsensitiveTransform,
  properties: {
    xmlns: null,
    xmlnsXLink: null
  }
});