'use strict';

var Info = require('./info');
var types = require('./types');
module.exports = DefinedInfo;
DefinedInfo.prototype = new Info();
DefinedInfo.prototype.defined = true;
var checks = ['boolean', 'booleanish', 'overloadedBoolean', 'number', 'commaSeparated', 'spaceSeparated', 'commaOrSpaceSeparated'];
var checksLength = checks.length;
function DefinedInfo(property, attribute, mask, space) {
  var index = -1;
  var check;
  mark(this, 'space', space);
  Info.call(this, property, attribute);
  while (++index < checksLength) {
    check = checks[index];
    mark(this, check, (mask & types[check]) === types[check]);
  }
}
function mark(values, key, value) {
  if (value) {
    values[key] = value;
  }
}