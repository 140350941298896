'use strict';

var create = require('./util/create');
module.exports = create({
  space: 'xml',
  transform: xmlTransform,
  properties: {
    xmlLang: null,
    xmlBase: null,
    xmlSpace: null
  }
});
function xmlTransform(_, prop) {
  return 'xml:' + prop.slice(3).toLowerCase();
}