'use strict';

module.exports = Schema;
var proto = Schema.prototype;
proto.space = null;
proto.normal = {};
proto.property = {};
function Schema(property, normal, space) {
  this.property = property;
  this.normal = normal;
  if (space) {
    this.space = space;
  }
}